<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider v-model:collapsed="collapsed" collapsible>
      <div class="logo" />
      <a-menu theme="dark" v-model:selectedKeys="selectedKeys" mode="inline">
        <router-link to="/admin">
          <a-menu-item key="home">
            <home-outlined />
            <span>Inicio</span>
          </a-menu-item>
        </router-link>
        <router-link to="/admin/super/requests">
          <a-menu-item key="requests">
            <team-outlined />
            <span>Solicitudes</span>
          </a-menu-item>
        </router-link>
        <router-link to="/admin/super/sales-history">
          <a-menu-item key="sales-history">
            <schedule-outlined />
            <span>Historial de ventas</span>
          </a-menu-item>
        </router-link>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #000; padding: 0"> </a-layout-header>
      <a-layout-content style="margin: 16px 16px">
        <!-- <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item>User</a-breadcrumb-item>
          <a-breadcrumb-item>Bill</a-breadcrumb-item>
        </a-breadcrumb> -->
        <div
          :style="{
            padding: '24px 24px 4px 24px',
            background: '#fff',
          }"
        >
          <router-view />
        </div>
      </a-layout-content>
      <a-layout-footer style="text-align: center">
        2021 © TONELAJE. Todos los derechos reservados
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import {
  TeamOutlined,
  ScheduleOutlined,
  HomeOutlined,
} from "@ant-design/icons-vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "SuperAdmin",
  components: {
    TeamOutlined,
    ScheduleOutlined,
    HomeOutlined,
  },
  setup() {
    const route = useRoute();
    const paths = route.path.split("/");
    return {
      collapsed: ref(false),
      selectedKeys: ref([paths[paths.length - 1]]),
    };
  },
};
</script>

<style scoped>
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}
</style>

<style>
.ant-menu-title-content {
  align-items: center;
  display: flex;
}
</style>
